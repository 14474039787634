<template>
    <section class="mx-auto max-w-7xl px-6 lg:px-8 pt-16 sm:pt-40">
        <div class="w-full justify-between items-center flex lg:flex-row flex-col lg:mb-16 mb-10 gap-12">
            <div class="flex-col justify-start lg:items-start items-center inline-flex">
                <h2 class="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl text-center">What Are Our
                    Customers Saying?
                </h2>
                <p class="mt-6 text-lg leading-8 text-white/50 sm:text-left text-center">More than 5000 users like
                    our
                    online reface site. Let's take a look at their reviews.</p>
            </div>
            <div class="justify-start items-start gap-1 flex">
                <button id="slider-button-left"
                    class="swiper-button-prev mr-6 group w-[52px] h-[52px] justify-center items-center flex shadow-xs bg-white bg-opacity-10 hover:bg-opacity-30 rounded-full transition-all duration-700 ease-in-out">
                    <svg class="text-white/60 hover:text-white transition-all duration-700 ease-in-out"
                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M9.06134 18.0612L3 11.9998M3 11.9998L9.06134 5.93848M3 11.9998H20.9999"
                            stroke="currentColor" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </button>
                <button id="slider-button-right"
                    class="swiper-button-next mr-6 group w-[52px] h-[52px] justify-center items-center flex shadow-xs bg-white bg-opacity-10 hover:bg-opacity-30 rounded-full transition-all duration-700 ease-in-out">
                    <svg class="text-white/60 hover:text-white transition-all duration-700 ease-in-out"
                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M14.9385 6L20.9999 12.0613M20.9999 12.0613L14.9385 18.1227M20.9999 12.0613L3 12.0613"
                            stroke="currentColor" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </button>
            </div>
        </div>
        <div class="swiper mySwiper">
            <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="item in items">
                    <div
                        class="p-6 bg-zinc-900 rounded-2xl shadow-[0px_15px_40px_-4px_rgba(16,_24,_40,_0.04)] flex-col justify-start items-start inline-flex">
                        <div class="flex-col justify-center items-start gap-6 flex">
                            <div class="justify-start items-center gap-3 inline-flex">
                                <div class="justify-start items-start flex">
                                    <div class="justify-start items-start gap-2.5 flex">
                                        <img class="h-12" :src="item.avatar" />
                                    </div>
                                </div>
                                <div class="flex-col justify-start items-start inline-flex">
                                    <h6 class="text-white text-base font-medium leading-relaxed">{{ item.name }}</h6>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="90" height="18" viewBox="0 0 90 18"
                                        fill="none">
                                        <g fill="#F59E0B">
                                            <path
                                                d="M9 1.5l2.34 4.74 5.22.76-3.78 3.68.89 5.19L9 13.47l-4.67 2.46.89-5.19L1.44 7l5.22-.76L9 1.5zM27 1.5l2.34 4.74 5.22.76-3.78 3.68.89 5.19L27 13.47l-4.67 2.46.89-5.19L19.44 7l5.22-.76L27 1.5zM45 1.5l2.34 4.74 5.22.76-3.78 3.68.89 5.19L45 13.47l-4.67 2.46.89-5.19L37.44 7l5.22-.76L45 1.5zM63 1.5l2.34 4.74 5.22.76-3.78 3.68.89 5.19L63 13.47l-4.67 2.46.89-5.19L55.44 7l5.22-.76L63 1.5zM81 1.5l2.34 4.74 5.22.76-3.78 3.68.89 5.19L81 13.47l-4.67 2.46.89-5.19L73.44 7l5.22-.76L81 1.5z" />
                                        </g>
                                    </svg>
                                </div>
                            </div>
                            <p class="text-white/50 text-base font-normal leading-relaxed">{{ item.content }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script setup lang="ts">
const utils = useUtils()

// 定义单个 item 的类型
interface Item {
    avatar: string;
    name: string;
    content: string;
}

// 定义 props，items 是一个 Item 对象数组
const props = defineProps({
    items: {
        type: Array as PropType<Item[]>,
        required: true
    }
});

onMounted(async () => {
    await utils.loadScript('https://cdn.jsdelivr.net/npm/swiper@11/swiper-bundle.min.js')
    var swiper = new Swiper(".mySwiper", {
        slidesPerView: 3,
        spaceBetween: 28,
        centeredSlides: true,
        loop: true,
        autoplay: {
            delay: 2000,
            disableOnInteraction: false,
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        breakpoints: {
            0: {
                slidesPerView: 1,
                spaceBetween: 20,
                centeredSlides: false,
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 28,
                centeredSlides: true,
            },
            1024: {
                slidesPerView: 3,
                spaceBetween: 32,
            },
        },
    });
})
</script>